import { convertJSONToArray } from "@utils/function";
import AddIconsToMap from "./addIconsToMap";

// --------------------------------------------------
// placec - group by name

// console.log("places!!!", HQ.Properties.places);

export default function reducePlaces(places) {
  return convertJSONToArray(places).reduce((acc, curr) => {
    const { id, icon_shortcode, name, name_tracking, icon, parent_id } = curr;
    if (!acc.get(parent_id)) {
      acc.set(id, {
        id,
        icon,
        name,
        name_tracking,
        icon_shortcode,
        children: [curr],
      });
    } else acc.get(parent_id)?.children?.push(curr);
    return acc;
  }, new Map());
}

// const placesObj = convertJSONToArray(HQ.Properties.places).reduce(
//   (acc, curr) => {
//     const { id, icon_shortcode, name, name_tracking, icon, parent_id } = curr;
//     if (!acc.get(parent_id)) {
//       acc.set(id, {
//         id,
//         icon,
//         name,
//         name_tracking,
//         icon_shortcode,
//         children: [curr],
//       });
//     } else acc.get(parent_id)?.children?.push(curr);
//     return acc;
//   },
//   new Map()
// );

window["resultsPlaces"] = [...reducePlaces(HQ.Properties.places).values()];

// add icons to map
AddIconsToMap(window["resultsPlaces"]);
